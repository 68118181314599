<template>
	<div class="tip-content">
		<div class="tip-info-icon-content">
			<span class="tip-info-text">i</span>
		</div>
		
		<label class="tip-title">{{$t('common.tips')}}</label>
		<label class="tip-sub-title" v-text="$t(tipInfo.title)"></label>
		
		<div class="column-center-content tip-info-content">
			<label class="tip-info-label">{{$t('fund.amount')}}:
				<span v-text="tipInfo.amount"></span>
			</label>
			<label class="tip-info-label">{{$t('tips.fromUser')}}:
				<span v-text="tipInfo.from"></span>
			</label>
			<label class="tip-info-label">{{$t('tips.toUser')}}:
				<span v-text="tipInfo.to"></span>
			</label>
			<!-- <label v-if="tipInfo.type === 'other'" class="tip-info-label">{{$t('tips.targetName')}}:
				<span v-if="tipInfo.targetName === '用户未实名'" style="color: red;">{{$t('tips.noName')}}</span>
				<span v-else v-text="tipInfo.targetName"></span>
			</label> -->
			<label class="tip-info-label">{{$t('tips.targetPhone')}}:
				<span v-text="tipInfo.targetPhone"></span>
			</label>
		</div>
		
		<div class="row-content tip-btn-content" style="justify-content: space-between;">
			<mt-button @click="closePop" type="danger" style="width: 80px;">{{$t('common.cancel')}}</mt-button>
			<mt-button @click="goTransfer" type="primary" style="width: 80px;">{{$t('common.ok')}}</mt-button>
		</div>
	</div>
</template>

<script>
	import "@/styles/tips.css"
	
	export default {
		name: 'TransferConfirmTip',
		props: {
			tipInfo: {
				type: Object,
				default: () => {
					return {
						title: '',
						amount: 0,
						from: '',
						to: '',
						targetName: '',
						targetPhone: ''
					}
				}
			}
		},
		methods: {
			closePop() {
				this.$parent.$parent.closePop();
			},
			goTransfer() {
				this.$parent.$parent.transfer();
			}
		}
	}
</script>

<style>
</style>
