<template>
	<div :style="bg">
		<Header :title="$t('fund.transferHk')" ></Header>
		
		<div class="column-center-content main-content">
			<div class="row-content" style="background-color: rgb(231,231,231); height: 40px; width: 90%; padding: 5px 16px; margin-bottom: 10px;">
				<label style="color: #FF0000; font-weight: bold;">{{$t('common.hk')}}:</label>
				<label style="color: #FF0000; margin-left: 5px;" v-text="hk"></label>
			</div>
			
			<div class="form-content">
				<label class="form-title">{{$t('assets.target')}}</label>
				<v-text-field :placeholder="$t('assets.target_hint')" color="success" style="width: 100%; padding-top: 5px;" v-model="targetUser"></v-text-field>
			</div>
			
			<div class="form-content">
				<label class="form-title">{{$t('fund.amount')}}</label>
				<v-text-field :placeholder="$t('fund.amount_hint')" color="success" style="width: 100%; padding-top: 5px;" v-model="amount" type="number"></v-text-field>
			</div>
			
			<div class="form-content">
				<label class="form-title">{{$t('assets.payPwd')}}</label>
				<v-text-field :placeholder="$t('assets.payPwd_hint')" color="success" style="width: 100%; padding-top: 5px;" v-model="pwd" type="password"></v-text-field>
			</div>
			
			<div v-if="gauth" class="form-content row-content">
				<v-text-field :placeholder="$t('google.googleCode_hint')" color="success" v-model="googleCode">
					<v-icon style="width: 20px; margin-right: 10px; color:#FF0000;" slot="prepend" >
						fa fa-envelope-open-o
					</v-icon>
				</v-text-field>
			</div>
			
			<div class="form-content row-content">
				<v-text-field :placeholder="$t('login.tac_hint')" color="success" v-model="code">
					<v-icon style="width: 20px; margin-right: 10px; color:#FF0000;" slot="prepend" >
						fa fa-envelope-open-o
					</v-icon>
				</v-text-field>
				<mt-button @click="sendCode" type="danger" plain style="height: 30px; border-radius: 15px; font-size: 10px;">{{$t('login.getTac')}}</mt-button>
			</div>
			
			<!-- <div class="row-content form-content" style="justify-content: flex-start;">
				<label class="form-title">{{$t('fund.fee')}}：</label>
				<label class="form-title" v-text="fee"></label>
			</div> -->
			
			<div style="padding: 10px 16px; color: #888888; font-size: 14px; text-align: justify; width: 100%;">
				<label>
					<span style="font-size: 16px;">{{$t('common.safeTitle')}}：<br/></span>
					{{$t('transfer.tips1')}}<br/>
					{{$t('transfer.tips2')}}<br/>
					{{$t('transfer.tips3')}}<br/>
				</label>
			</div>
			
			<div class="btn-content">
				<v-btn rounded block @click="showConfirmTip" style="background-color: #009245; color: white; height: 40px;">
					{{$t('common.submit')}}
				</v-btn>
			</div>
		</div>
		
		<mt-popup style="max-width: 320px; width: 80%; align-items: center; border-radius: 10px;" v-model="confirmTipVisible" popup-transition="popup-fade">
			<TransferConfirmTip :tipInfo="tipInfo"></TransferConfirmTip>
		</mt-popup>
		
		<mt-popup style="max-width: 320px; width: 80%; align-items: center; border-radius: 10px;" v-model="successTipVisible" popup-transition="popup-fade">
			<TransferSuccessTip :tipInfo="tipInfo"></TransferSuccessTip>
		</mt-popup>
	</div>
</template>

<script>
	import "@/styles/common.css"
	import { Indicator } from 'mint-ui';
	import { Toast } from 'mint-ui';
	import Header from '../../../components/NavHeader.vue'
	import TransferConfirmTip from '../../../components/TransferConfirmTip.vue'
	import TransferSuccessTip from '../../../components/TransferSuccessTip.vue'
	
	export default {
		name: 'TransferHk',
		components: {
			Header,
			TransferConfirmTip,
			TransferSuccessTip
		},
		data() { 
			return {
				bg : {
					height: "100vh",
					width: "100%",
					backgroundImage: "url(" + require("../../../assets/login/new-bg.png") + ")",
					backgroundRepeat: "no-repeat",
					backgroundPosition: "fixed",
					backgroundSize: "100% 100%",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					overflow: "auto"
				},
				gauth: localStorage.getItem('gauth'),
				hk: JSON.parse(localStorage.getItem('walletInfo')).hk ? JSON.parse(localStorage.getItem('walletInfo')).hk : 0,
				targetUser: '',
				targetName: '',
				targetPhone: '',
				amount: '',
				pwd: '',
				code: '',
				googleCode: '',
				fee: '1',
				confirmTipVisible: false,
				successTipVisible: false,
				tipInfo: {
					title: '',
					amount: 0,
					from: '',
					to: '',
					targetName: '',
					targetPhone: ''
				}
			}
		},
		methods: {
			sendCode() {
				Indicator.open({
					text: '',
					spinnerType: 'fading-circle'
				});
				
				let phone = JSON.parse(localStorage.getItem('userInfo')).phone;
				let params = {
					phone: phone
				}
				this.$request.sendCode(
					params
				).then(()=>{
					Indicator.close();
				});
			},
			
			showConfirmTip() {
				
				let target = this.$data.targetUser;
				let amount = this.$data.amount;
				let pwd = this.$data.pwd;
				let code = this.$data.code;
				
				if(null == target || '' == target) {
					Toast({
						message: this.$i18n.t("assets.target_hint"),
						position: 'bottom'
					});
					return;
				}
				
				if(null == amount || '' == amount) {
					Toast({
						message: this.$i18n.t("fund.amount_hint"),
						position: 'bottom'
					});
					return;
				}
				
				if(amount <= 0) {
					Toast({
						message: this.$i18n.t("fund.amount_error"),
						position: 'bottom'
					});
					return;
				}
				
				if(null == pwd || '' == pwd) {
					Toast({
						message: this.$i18n.t("assets.payPwd_hint"),
						position: 'bottom'
					});
					return;
				}
				
				if(null == code || '' == code) {
					Toast({
						message: this.$i18n.t("login.tac_hint"),
						position: 'bottom'
					});
					return;
				}
				
				// 请求接收用户的信息
				Indicator.open({
					text: '',
					spinnerType: 'fading-circle'
				});
				this.$request.getTargetInfo({
					account: target
				}).then((data)=>{
					this.$data.targetName = data.name;
					this.$data.targetPhone = data.phone;
					Indicator.close();
					this.$data.tipInfo = {
						title: 'fund.transferHk',
						amount: amount,
						from: JSON.parse(localStorage.getItem('userInfo')).account,
						to: target,
						targetName: data.name,
						targetPhone: data.phone
					}
					// 弹窗提示确认互转信息
					this.$data.confirmTipVisible = true;
				});
			},
			
			closePop() {
				this.$data.confirmTipVisible = false;
				this.$data.successTipVisible = false;
			},
			
			transfer() {
				this.$data.confirmTipVisible = false;
				
				let target = this.$data.targetUser;
				let amount = this.$data.amount;
				let pwd = this.$data.pwd;
				let code = this.$data.code;
				
				let params = {
					amount: amount,
					production: 'hk',
					pwd: pwd,
					target: target,
					code: code,
					gauth: this.$data.googleCode
				}
				Indicator.open({
					text: '',
					spinnerType: 'fading-circle'
				});
				
				this.$request.transfer(
					params
				).then(()=>{
					Indicator.close();
					sessionStorage.setItem('refreshInfo', '1');
					this.$data.tipInfo = {
						title: 'fund.transferHk',
						amount: this.$data.amount,
						from: JSON.parse(localStorage.getItem('userInfo')).account,
						to: this.$data.targetUser,
						targetName: this.$data.targetName,
						targetPhone: this.$data.targetPhone
					}
					// 互转成功，展示成功弹窗
					this.$data.successTipVisible = true;					
				});
			},
			
			goBack() {
				this.$router.go(-1);
			}
		}
	}
</script>

<style>
</style>
